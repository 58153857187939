import axios, { AxiosInstance } from 'axios';
import { Store } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { HttpMethod } from '../utils/types';
import { base, endpointAuth, token, tokenRefreshUrl } from '../settings';
import { clearUserSlice, setToken } from './features/user/userSlice';
import { logoutUser } from './features/user/userServices';

let instance: AxiosInstance | null = null;
const history = createBrowserHistory();

export function initializeInstance(store: Store) {
  instance = axios.create({
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  });

  instance.interceptors.request.use(
    (config: any) => {
      const { origin } = new URL(config.url);
      const allowedOrigins = [base];
      if (allowedOrigins.includes(origin)) {
        if (instance) {
          instance.defaults.headers['Authorization'] = token;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { status } = error.response;
      const originalRequest = error.config;

      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const state = store.getState();
        const { refresh_token } = state.user.currentUser;

        if (refresh_token) {
          try {
            const { data } = await instance!.post(
              tokenRefreshUrl,
              { refresh_token },
              {
                headers: {
                  Accept: 'application/json',
                  'content-type': 'application/json',
                  Authorization: endpointAuth,
                },
              },
            );

            store.dispatch(setToken(data.access_token));
            originalRequest.headers['Authorization'] = data.access_token;
            return instance?.(originalRequest);
          } catch (err: any) {
            store.dispatch(clearUserSlice());
            const { userType } = state.user.userType;
            if (userType !== 'guest') {
              store.dispatch<any>(logoutUser());
            }
            history.push('/');
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    },
  );
}

export function getAxiosInstance() {
  if (!instance) {
    throw new Error('Axios has not been initialized. Please call initializeInstance first.');
  }
  return instance;
}

export async function sendRequest(
  method: HttpMethod,
  url: string,
  data?: any,
  access_token?: string,
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | 'stream',
) {
  const instance = getAxiosInstance();
  try {
    const headers = {
      Authorization: `${access_token}`,
      'Content-Type': 'multipart/form-data',
    };

    let response;

    const config = { headers, responseType };

    switch (method) {
      case 'get':
        response = await instance.get(url, { ...config, params: data });
        break;
      case 'post':
        response = await instance.post(url, data, config);
        break;
      case 'put':
        response = await instance.put(url, data, config);
        break;
      case 'patch':
        response = await instance.patch(url, data, config);
        break;
      case 'delete':
        response = await instance.delete(url, { ...config, data });
        break;
      default:
        return Promise.reject('Invalid method');
    }

    return { data: response.data, status: response.status };
  } catch (error: any) {
    return Promise.reject(error);
  }
}
