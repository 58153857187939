import axios from 'axios';
import { distance, point } from '@turf/turf';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { mapbox_token } from 'settings';
import { OrderLocation, PrintShop } from 'utils/types';
import i18n from 'i18nconfig';
import {
  setDefaultLocation,
  setDistanceToShop,
  setGeojson,
  setSelectedShop,
  setSnackbar,
  setUserLocation,
  setZoom,
} from 'store/features/app/appSlice';
import { store } from 'store/configureStore';
import { Coordinates, UserLocationDetectArgs } from './types/MapTypes';

export const getCountryLocation: UserLocationDetectArgs = async (
  setUserLocation: (location: Coordinates) => void,
  setZoom: (zoom: number) => void,
) => {
  try {
    const response = await axios.get(`https://ipapi.co/json/?key=${[process.env.REACT_APP_IP_API_KEY]}`);
    const location = {
      longitude: response.data.longitude,
      latitude: response.data.latitude,
    };
    setDefaultLocation(location);
    setZoom(8); // Providing default zoom level

    return { location, zoom: 10 };
  } catch (error) {
    console.error('Error fetching country location:', error);
    setUserLocation({
      longitude: 5.9620000000000175,
      latitude: 49.39186024475177,
    });
    setZoom(1); // Default zoom level for Europe
    return { location: { longitude: 0, latitude: 0 }, zoom: 1 };
  }
};

export const getCurrentRoute = async (userLocation: Coordinates, selectedShop: any) => {
  if (!selectedShop || !userLocation) {
    return null;
  }

  try {
    const lon = userLocation.longitude;
    const lat = userLocation.latitude;
    const shopLon = selectedShop?.lon; // Adjusted this line
    const shopLat = selectedShop?.lat; // Adjusted this line
    const response = await axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${lon},${lat};${shopLon},${shopLat}?geometries=geojson&access_token=${mapbox_token}`,
    );

    if (response?.data?.routes) {
      const routeData = response?.data?.routes[0];
      const routeCoordinates = routeData?.geometry?.coordinates;

      const newGeojson = {
        type: 'FeatureCollection' as const,
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: routeCoordinates?.map((coordinate: [number, number]) => [coordinate[0], coordinate[1]]),
            },
            properties: {},
          },
        ],
      };

      return newGeojson;
    }
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const calculateDistanceAndTime = (shopLocation: PrintShop, userLocation: Coordinates, dispatch: any) => {
  if (!shopLocation || !userLocation) {
    return;
  }

  const storePoint = point([parseFloat(shopLocation.lon), parseFloat(shopLocation.lat)]);
  const userPoint = point([userLocation.longitude, userLocation.latitude]);
  const distanceInMeters = distance(userPoint, storePoint, { units: 'meters' });
  const distanceInKilometers = Math.floor(distanceInMeters / 1000);
  const remainingMeters = Math.round(distanceInMeters % 1000);
  const time = distanceInMeters / 3000;
  const hours = Math.floor(time);
  const minutes = Math.round((time % 1) * 60);

  dispatch(
    setDistanceToShop({
      distance: `${i18n.t('map.km', { distance: distanceInKilometers })} ${i18n.t('map.meters', {
        distance: remainingMeters,
      })}`,
      time: i18n.t('map.hm', { hours: hours, mins: minutes }),
    }),
  );
};

export const getNewRoute = async (location: PrintShop, userLocation: Coordinates, dispatch: any) => {
  const newGeojson = await getCurrentRoute(userLocation, location);

  dispatch(setGeojson(newGeojson));

  dispatch(setSelectedShop(location));
  calculateDistanceAndTime(location, userLocation, dispatch);
};

export const getCountryName = (country: string, t: any) => {
  return country === 'Bulgaria' || country === 'BG' ? t('countryPicker.bg') : t('countryPicker.uk');
};

export const geocodingClient = mbxGeocoding({ accessToken: mapbox_token || '' });

export const handleMapDisplayLocation = async (
  city: string,
  country: string,
  language: string[],
  dispatch: Dispatch,
  t: any,
) => {
  try {
    const response = await geocodingClient
      .forwardGeocode({
        query: `${city}, ${country}`, // include the city in the query
        autocomplete: false,
        limit: 1,
        countries: [country],
        language: language,
        types: ['country', 'region', 'postcode', 'place'], // include 'place' in the types to search for cities
      })
      .send();
    if (response && response.body && response.body.features && response.body.features.length > 0) {
      const [lng, lat] = response.body.features[0].center;
      dispatch(setUserLocation({ latitude: lat, longitude: lng }));
      dispatch(setZoom(8));
    } else {
      dispatch(setSnackbar({ open: true, message: t('map.noResults'), type: 'error' }));
    }
  } catch (error) {
    dispatch(setSnackbar({ open: true, message: t('map.noResultsSubtitle'), type: 'error' }));
  }
};

// TODO: Rework for BG time
export const calculateTheWorkingTime = (item: OrderLocation) => {
  let isWorking = false;
  let workingTime: any = [undefined, undefined];
  if (Object.keys(item).length > 0) {
    if (Object.keys(item.opening_times).length > 0) {
      const current_day = moment().format('dddd').toLowerCase();
      //calculate working time
      const hour_at_the_moment = Number(moment().format('HH'));
      const daysOpen = Object.entries(item.opening_times).filter((day: Array<string>) => {
        if (day[1]?.toLowerCase() !== 'day off' && day[1]?.toLowerCase() !== 'почивен ден') {
          return day;
        }
        return null;
      });
      if (!(daysOpen[0][1] === 'For residents only')) {
        workingTime = daysOpen.filter((workingDay) => {
          if (workingDay[0] === current_day) {
            return workingDay;
          }
          return null;
        });
        if (workingTime[0]) {
          if (workingTime[0][1].includes('-')) {
            workingTime = workingTime[0][1].split('-');
            const start_time = Number(workingTime[0].split(':')[0]);
            const end_time = Number(workingTime[1].split(':')[0]);
            if (start_time <= hour_at_the_moment && hour_at_the_moment < end_time) {
              isWorking = true;
            }
          }
        }
      } else {
        workingTime = daysOpen[0];
      }
    }
  }
  return { isWorking, workingTime };
};

export const getLocationByIp = () => {
  axios
    .get('https://ipapi.co/json/')
    .then((response) => {
      const country = response.data.country;
      const location = {
        longitude: response.data.longitude,
        latitude: response.data.latitude,
      };

      store.dispatch(setUserLocation(location));
      store.dispatch(setZoom(10));
    })
    .catch((error) => {
      console.error('Error getting user location:', error);
    });
};
