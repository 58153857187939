import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TeamReducer } from '../../../utils/types';
import {
  createTeam,
  getTeam,
  getTeamAnalytics,
  getTeamBillingDetails,
  getTeamInvitations,
  getTeamInvoices,
  getTeamMembers,
  inviteTeamMembers,
  postTeamLimit,
  removeTeamMember,
  validateTeamCard,
} from './teamServices';

const initialState: TeamReducer = {
  loading: 'idle',
  error: '',
  teamMembers: [],
  teamInvitations: [],
  membersInvitationSent: false,
  ukAddresses: [],
  teamInfo: null,
  billingDetails: null,
  teamAnalytics: null,
  teamOrdersList: [],
  invoices: [],
  teamStep: 0,
  userPaymentMethods: [],
  teamMembersFrontMessage: false,
  company_name: '',
  company_domain: '',
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeamName: (state, action: PayloadAction<any>) => {
      state.company_name = action.payload;
    },
    setTeamDomain: (state, action: PayloadAction<string>) => {
      state.company_domain = action.payload;
    },
    setTeamStep: (state, action: PayloadAction<number>) => {
      state.teamStep = action.payload;
    },
    clearTeamSlice: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeam.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = 'succeeded';
        state.teamInfo = action.payload;
      })
      .addCase(createTeam.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeam.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = 'succeeded';
        state.teamInfo = action.payload;
      })
      .addCase(getTeam.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeam.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(inviteTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamInvitations = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(inviteTeamMembers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(inviteTeamMembers.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeamBillingDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.billingDetails = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getTeamBillingDetails.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeamBillingDetails.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeamAnalytics.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamAnalytics = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getTeamAnalytics.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeamAnalytics.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeamMembers.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamMembers = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getTeamMembers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeamMembers.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeamInvoices.fulfilled, (state, action: PayloadAction<any>) => {
        state.invoices = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getTeamInvoices.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeamInvoices.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(removeTeamMember.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamMembers = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(removeTeamMember.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(removeTeamMember.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(postTeamLimit.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamInfo = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(postTeamLimit.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postTeamLimit.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getTeamInvitations.fulfilled, (state, action: PayloadAction<any>) => {
        state.teamInvitations = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getTeamInvitations.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTeamInvitations.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(validateTeamCard.fulfilled, (state, action: PayloadAction<any>) => {
        state.userPaymentMethods = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(validateTeamCard.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(validateTeamCard.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      });
  },
});
export const { setTeamStep, setTeamName, setTeamDomain, clearTeamSlice } = teamSlice.actions;

export default teamSlice.reducer;
