import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import i18n from 'i18nconfig';
import PrivateRoute from 'PrivateRoute';

import Spinner from 'components/Spinner';
import { closeSnackbar } from 'store/features/app/appSlice';
import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import { getCountry } from 'utils/helpers';
import { useGuestUserInitialization } from 'utils/hooks';

// Lazy load components
const Homepage = lazy(() => import('./pages/Homepage'));
const Authpage = lazy(() => import('./pages/Profile/Authpage'));
const RegisterComponent = lazy(() => import('./components/Auth/Register'));
const LoginComponent = lazy(() => import('./components/Auth/Login'));
const ConfirmPaymentPage = lazy(() => import('./pages/Checkout/ConfirmPaymentPage'));
const InstantOrder = lazy(() => import('./pages/Order'));
const ProfilePage = lazy(() => import('./pages/Profile/Profilepage'));
const Orderspage = lazy(() => import('./pages/Teams/Orderspage'));
const OrderDetails = lazy(() => import('./components/Table/OrderDetails'));
const AddTeampage = lazy(() => import('./pages/Profile/AddTeampage'));
const Teampage = lazy(() => import('./pages/Teams/Teampage'));
const Bilingpage = lazy(() => import('./pages/Teams/Billingpage'));
const Page404 = lazy(() => import('./pages/Page404'));
import FileStatusListener from './components/PrintFileLIstener';
import PrintSnackBar from './components/PrintSnackBar';

type RouteTypes = {
  path: string;
  element: React.ReactElement;
  isPrivate?: boolean;
};

const routes: RouteTypes[] = [
  { path: '/', element: <Homepage /> },
  { path: '/auth', element: <Authpage /> },
  { path: '/register', element: <RegisterComponent /> },
  { path: '/login', element: <LoginComponent /> },
  { path: '/congratulations', element: <ConfirmPaymentPage /> },
  { path: '/instantOrder', element: <InstantOrder /> },
  { path: '/profile', element: <ProfilePage />, isPrivate: true },
  { path: '/orders', element: <Orderspage />, isPrivate: true },
  { path: '/orders/:id', element: <OrderDetails />, isPrivate: true },
  { path: '/addteam', element: <AddTeampage />, isPrivate: true },
  { path: '/team', element: <Teampage />, isPrivate: true },
  { path: '/biling', element: <Bilingpage />, isPrivate: true },
  { path: '/404', element: <Page404 /> },
];

const Router = () => {
  const { snackbar } = useAppSelector((state) => state.app);
  const { userType } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const isInitialized = useGuestUserInitialization(userType);

  const getBrowserLanguage = useCallback(async () => {
    const country = await getCountry();
    if (country === 'BG') {
      i18n.changeLanguage('bg');
    }
  }, []);

  useEffect(() => {
    getBrowserLanguage();
  }, [getBrowserLanguage]);

  const handleClose = useCallback(() => {
    dispatch(closeSnackbar());
  }, [dispatch]);

  if (!isInitialized) {
    return <Spinner />;
  }

  return (
    <BrowserRouter basename='/'>
      <Suspense fallback={<Spinner />}>
        <FileStatusListener />
        <PrintSnackBar
          open={snackbar.open}
          messages={[snackbar.message]}
          handleClose={handleClose}
          type={snackbar.type}
        />
        <Routes>
          {routes.map(({ path, element, isPrivate }) => (
            <Route
              key={path}
              path={path}
              element={isPrivate ? <PrivateRoute element={element} isPrivate={isPrivate} /> : element}
            />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
