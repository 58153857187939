import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en/translation.json'),
      },
      bg: {
        translation: require('./locales/bg/translation.json'),
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'bg'],
    debug: false,
  });

export default i18n;
