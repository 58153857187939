import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { persistStore } from 'redux-persist';

import { initializeInstance } from './api';
import rootReducer from './persist';

const sentryMiddleware = (storeAPI: { getState: () => any }) => (next: (arg0: any) => any) => (action: any) => {
  const state = storeAPI.getState();
  Sentry.getCurrentScope().setTag('state', state);
  Sentry.addBreadcrumb({
    category: 'redux-action',
    message: action.type,
    data: {
      action: action,
    },
  });

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: sentryMiddleware,
      },
      serializableCheck: false,
    }),
});

initializeInstance(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);

export { store, persistor };
