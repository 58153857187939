import { createAsyncThunk } from '@reduxjs/toolkit';

import i18n from 'i18nconfig';

import { getStateAndToken } from '../../../utils/helpers';
import {
  authorizeTeamCard,
  limitsUrl,
  settings,
  teamsAnalyticsUrl,
  teamsBillingUrl,
  teamsDetailsUrl,
  teamsInvitationsUrl,
  teamsInvoicesUrl,
  teamsMembersListUrl,
} from '../../../settings';
import { sendRequest } from '../../api';
import { TeamInvitation } from '../../../utils/types';
import { DateRange } from '../order/orderTypes';
import { setSnackbar } from '../app/appSlice';

export const createTeam = createAsyncThunk('team/createTeam', async (teamName: string, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const body = {
      name: teamName,
    };

    const { data, status } = await sendRequest('post', `${teamsDetailsUrl}`, body, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    let errorMessage;
    if (error.response) {
      errorMessage = Array.isArray(error.response.data) ? error.response.data[0] : error.response.data.message;
    } else {
      errorMessage = error.message;
    }
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const inviteTeamMembers = createAsyncThunk(
  'team/inviteTeamMembers',
  async (invitations: TeamInvitation[], thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);
      const { data, status } = await sendRequest('post', teamsInvitationsUrl, invitations, `Bearer ${access_token}`);
      if (status === 201) {
        thunkApi.dispatch(getTeamInvitations());
      }
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const getTeam = createAsyncThunk('team/getTeam', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data, status } = await sendRequest('get', `${teamsDetailsUrl}`, null, `Bearer ${access_token}`);

    if (status === 200) {
      thunkApi.dispatch(getTeamBillingDetails());
      thunkApi.dispatch(getTeamMembers());
    }

    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return null;
  }
});

export const getTeamBillingDetails = createAsyncThunk('team/getTeamBillingAddress', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest('get', `${teamsBillingUrl}`, null, `Bearer ${access_token}`);
    return {
      city: data.city,
      country: data.country,
      financialManager: data.financial_manager,
      line1: data.line1,
      line2: data.line2,
      company_name: data.name,
      postal_code: data.postal_code,
      vat_number: data.vat_number,
      brand: data.brand,
      last4: data.last4,
    };
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const postTeamBillingDetails = createAsyncThunk(
  'team/postTeamBillingDetails',
  async (formData: any, thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);
      const { data } = await sendRequest('post', `${teamsBillingUrl}`, formData, `Bearer ${access_token}`);
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const validateTeamCard = createAsyncThunk('team/validateCard', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const body = {
      stripe_pi_secret: settings.stripe_pk,
    };
    const { data } = await sendRequest('post', authorizeTeamCard, body, `Bearer ${access_token}`);
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('checkout.cardAuthenticated'), type: 'success' }));
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getTeamAnalytics = createAsyncThunk('team/getTeamAnalytics', async (dateRange: DateRange, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const body = {
      start_date: `${dateRange.start_date}`,
      end_date: `${dateRange.end_date}`,
    };
    const { data } = await sendRequest('get', `${teamsAnalyticsUrl}`, body, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getTeamMembers = createAsyncThunk('team/getTeamMembers', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest(
      'get',
      `${teamsMembersListUrl}`,
      { id: state.user.currentUser.user_id },
      `Bearer ${access_token}`,
    );
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getTeamInvoices = createAsyncThunk('team/getTeamInvoices', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest('get', `${teamsInvoicesUrl}`, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const removeTeamMember = createAsyncThunk('team/removeTeamMember', async (memberId: string, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest('delete', `${teamsMembersListUrl}${memberId}`, null, `Bearer ${access_token}`);
    thunkApi.dispatch(getTeamMembers());
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const postTeamLimit = createAsyncThunk('team/setTeamLimit', async (limit: number, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const body = {
      monthly_spending_limit: limit,
    };
    const { data } = await sendRequest('put', `${limitsUrl}`, body, `Bearer ${access_token}`);
    thunkApi.dispatch(getTeam());
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getTeamInvitations = createAsyncThunk('team/getInvitations', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest(
      'get',
      `${teamsInvitationsUrl}`,
      { id: state.user.currentUser.user_id },
      `Bearer ${access_token}`,
    );
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});
