import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import LoadingIcon from '../../assets/img/loader.svg';
import './style.css'; // Import the CSS file

type SpinnerProps = { hasText?: boolean };

const Spinner: React.FC<SpinnerProps> = ({ hasText = true }) => {
  const { t } = useTranslation();

  return (
    <div className={'spinnerWrapper'}>
      <img
        className='spinner' // Apply the spinner class
        loading='eager'
        src={LoadingIcon}
        alt='Loading'
      />
      {hasText && (
        <Typography variant='body1' component='h4' style={{ color: '#0DAB95' }}>
          {t('main.loading')}
        </Typography>
      )}
    </div>
  );
};

export default Spinner;
