import React, { useCallback, useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useAppSelector } from 'store/reduxHooks';
import { useIsDesktop } from 'utils/helpers';
import { intercome_id } from '../../settings';

const PrintSupport = ({ children }: { children: React.ReactNode }) => {
  const { boot, shutdown, show } = useIntercom();
  const { currentUser } = useAppSelector((state) => state.user);
  const { user_id } = currentUser;
  useEffect(() => {
    if (user_id) {
      setTimeout(() => {
        boot({
          userId: user_id?.toString(),
          // Add other user details here
        });
      }, 500); // 500ms delay to ensure Intercom is ready
    }
    return () => {
      shutdown();
    };
  }, [boot, shutdown, user_id]);

  const handleClick = useCallback(() => {
    show(); // This will show the Intercom Messenger
  }, [show]);

  return (
    <div>
      <button style={{ display: 'none' }} onClick={handleClick}></button>
      {children}
    </div>
  );
};

const PrintSupportWithIntercom = ({ children }: { children: React.ReactNode }) => {
  return (
    <IntercomProvider appId={`${intercome_id}`} autoBoot>
      <PrintSupport>{children}</PrintSupport>
    </IntercomProvider>
  );
};

export default PrintSupportWithIntercom;
