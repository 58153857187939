import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FileInfo, FileReducer } from './types';
import {
  deleteUploadedFile,
  getFileInfo,
  getPdfFiles,
  getUploadedFiles,
  handleResetFileUpload,
  handleUploadFile,
} from './fileServices';

const initialState: FileReducer = {
  loading: 'idle',
  uploadedFiles: [],
  fileInfo: '',
  pdfDocs: null,
  isUploading: false,
};

const setPending = (state: FileReducer) => {
  state.loading = 'pending';
  state.isUploading = true;
};

const setFulfilled = (state: FileReducer) => {
  state.loading = 'succeeded';
  state.isUploading = false;
};

const setRejected = (state: FileReducer) => {
  state.loading = 'failed';
  state.isUploading = false;
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setUploadedFiles: (state, action: PayloadAction<FileInfo[]>) => {
      state.uploadedFiles = action.payload;
    },
    clearFileSlice: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleUploadFile.pending, setPending)
      .addCase(handleUploadFile.fulfilled, (state, action: PayloadAction<FileInfo[]>) => {
        setFulfilled(state);
        state.uploadedFiles = action.payload;
      })
      .addCase(handleUploadFile.rejected, setRejected)
      .addCase(deleteUploadedFile.pending, setPending)
      .addCase(deleteUploadedFile.fulfilled, (state, action: PayloadAction<any>) => {
        setFulfilled(state);
        state.pdfDocs = state.pdfDocs
          ? state.pdfDocs.filter((doc: unknown) => (doc as any).id !== action.payload.id)
          : null;
      })
      .addCase(deleteUploadedFile.rejected, setRejected)
      .addCase(getUploadedFiles.pending, setPending)
      .addCase(getUploadedFiles.fulfilled, (state, action) => {
        setFulfilled(state);
        state.uploadedFiles = action.payload;
      })
      .addCase(getUploadedFiles.rejected, setRejected)
      .addCase(getFileInfo.pending, setPending)
      .addCase(getFileInfo.fulfilled, (state, action) => {
        setFulfilled(state);
        state.fileInfo = action.payload;
      })
      .addCase(getFileInfo.rejected, setRejected)
      .addCase(handleResetFileUpload.pending, setPending)
      .addCase(handleResetFileUpload.fulfilled, setFulfilled)
      .addCase(handleResetFileUpload.rejected, setRejected)
      .addCase(getPdfFiles.pending, setPending)
      .addCase(getPdfFiles.fulfilled, (state, action) => {
        setFulfilled(state);
        state.pdfDocs = [...(state.pdfDocs || []), action.payload];
      })
      .addCase(getPdfFiles.rejected, setRejected);
  },
});

export const { setUploadedFiles, clearFileSlice } = fileSlice.actions;

export default fileSlice.reducer;
