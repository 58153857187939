import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  EuropeBboxCoordinates,
  listPaymentMethodsUrl,
  printersCodeUrl,
  printersLocationsUrl,
  shopsLocationsUrl,
  stripePaymentIntentUrl,
} from '../../../settings';
import { getStateAndToken } from '../../../utils/helpers';
import { sendRequest } from '../../api';

export const getShopsLocation = createAsyncThunk('app/getShopsLocation', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest(
      'get',
      `${shopsLocationsUrl}&tl_lat=${EuropeBboxCoordinates[1]}&tl_lon=${EuropeBboxCoordinates[0]}&br_lat=${EuropeBboxCoordinates[3]}&br_lon=${EuropeBboxCoordinates[2]}`,
      null,
      `Bearer ${access_token}`,
    );

    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getPrintersLocation = createAsyncThunk('app/getPrintersLocation', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest(
      'get',
      `${printersLocationsUrl}&tl_lat=${EuropeBboxCoordinates[1]}&tl_lon=${EuropeBboxCoordinates[0]}&br_lat=${EuropeBboxCoordinates[3]}&br_lon=${EuropeBboxCoordinates[2]}`,
      null,
      `Bearer ${access_token}`,
    );

    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const createPaymentIntent = createAsyncThunk(
  'app/createPaymentIntent',
  async ({ order_id, service_type }: { order_id: string; service_type: string }, thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);
      const body = { service_type, order_id };

      const { data } = await sendRequest('post', stripePaymentIntentUrl, body, `Bearer ${access_token}`);

      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const getPaymentList = createAsyncThunk('app/getPaymentList', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { service_type } = state.order;
    const body = {
      service_type:
        service_type === 'click_and_collect' ? 'collect' : service_type === 'delivery' ? 'delivery' : 'printt_now',
    };
    const { data } = await sendRequest('post', listPaymentMethodsUrl, body, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const handlePrinterScanCode = createAsyncThunk(
  'app/handlePrinterScanCode',
  async ({ selectedPrinter }: { selectedPrinter: any }, thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);
      const body = {
        text: selectedPrinter,
      };
      const { data } = await sendRequest(
        'get',
        `${printersCodeUrl}/${selectedPrinter}`,
        body,
        `Bearer ${access_token}`,
      );
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);
