import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { FeatureCollection } from 'geojson';
import { getPaymentList, getPrintersLocation, getShopsLocation, handlePrinterScanCode } from './appServices';

import { Loading, PrintShop } from '../../../utils/types';

type SnackbarState = {
  open: boolean;
  message: string;
  type: 'success' | 'error' | 'warning';
};

export type AppSlice = {
  loading: Loading;
  internetState: boolean;
  error: string;
  shops: PrintShop[];
  printers: PrintShop[] | null;
  selectedShop: PrintShop | null;
  selectedPrinter: any | null;
  shopDistance: any;
  geoJson: FeatureCollection | null;
  paymentsMethods: any[];
  locationPermission: boolean;
  snackbar: SnackbarState;
  userLocation: {
    latitude: number;
    longitude: number;
  };
  defaultLocation: {
    latitude: number;
    longitude: number;
  };
  zoom: number;
  deliveryPrice: number;
};

const initialState: AppSlice = {
  loading: 'idle',
  internetState: true,
  error: '',
  shops: [],
  printers: [],
  selectedShop: null,
  selectedPrinter: null,
  shopDistance: null,
  geoJson: null,
  paymentsMethods: [],
  locationPermission: false,
  snackbar: { open: false, message: '', type: 'success' },
  userLocation: { latitude: 0, longitude: 0 },
  defaultLocation: { latitude: 49.39186024475177, longitude: 5.9620000000000175 },
  zoom: 3,
  deliveryPrice: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAllShopsLocations: (state, action: PayloadAction<any>) => {
      state.shops = action.payload;
    },
    setAllPrintersLocations: (state, action: PayloadAction<any>) => {
      state.printers = action.payload;
    },
    setSelectedShop: (state, action: PayloadAction<PrintShop | null>) => {
      state.selectedShop = action.payload;
    },
    setDistanceToShop: (state, action: PayloadAction<any>) => {
      state.shopDistance = action.payload;
    },
    setGeojson: (state, action: PayloadAction<any>) => {
      state.geoJson = action.payload;
    },
    setLocationPermission: (state, action) => {
      state.locationPermission = action.payload;
    },
    setSnackbar: (state, action: PayloadAction<SnackbarState>) => {
      state.snackbar = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<any>) => {
      state.paymentsMethods = action.payload;
    },
    setUserLocation: (state, action: PayloadAction<any>) => {
      state.userLocation = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setDefaultLocation: (state, action: PayloadAction<any>) => {
      state.defaultLocation = action.payload;
    },
    setDeliveryPrice: (state, action: PayloadAction<number>) => {
      state.deliveryPrice = action.payload;
    },
    closeSnackbar: (state) => {
      state.snackbar.open = false;
    },
    clearAppSlice: (state) => {
      return { ...initialState };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getShopsLocation.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(getShopsLocation.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.shops = action.payload;
    });
    builder.addCase(getShopsLocation.rejected, (state, action) => {
      state.loading = 'failed';
    });
    builder.addCase(getPrintersLocation.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(getPrintersLocation.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.printers = action.payload;
    });
    builder.addCase(getPrintersLocation.rejected, (state, action) => {
      state.loading = 'failed';
    });
    builder.addCase(getPaymentList.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(getPaymentList.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.paymentsMethods = action.payload;
    });
    builder.addCase(getPaymentList.rejected, (state, action) => {
      state.loading = 'failed';
    });
    builder.addCase(handlePrinterScanCode.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.selectedPrinter = action.payload;
    });
    builder.addCase(handlePrinterScanCode.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(handlePrinterScanCode.rejected, (state, action) => {
      state.loading = 'failed';
    });
  },
});

export const {
  setLocationPermission,
  setAllShopsLocations,
  clearAppSlice,
  setSelectedShop,
  setDistanceToShop,
  setGeojson,
  setSnackbar,
  setPaymentMethods,
  setUserLocation,
  setZoom,
  setDefaultLocation,
  setDeliveryPrice,
  closeSnackbar,
} = appSlice.actions;

export default appSlice.reducer;
