import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: '#f44242',
  },
  success: {
    backgroundColor: '#05b59c',
  },
  warning: {
    backgroundColor: '#ffcd84',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: 8,
  },
  content: {},
  message: {},
}));
