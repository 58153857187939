import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/reduxHooks';

interface PrivateRouteProps {
  element: React.ReactElement;
  isPrivate?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, isPrivate }) => {
  const { userType } = useAppSelector((state) => state.user);
  const location = useLocation();

  if (isPrivate && userType === 'guest') {
    return <Navigate to='/' state={{ from: location }} />;
  }

  return element;
};

export default PrivateRoute;
