import React from 'react';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { CheckCircleOutline, Close, ErrorOutline, WarningOutlined } from '@mui/icons-material';
import { useStyles } from './styles';

type CustomSnackbarProps = {
  open: boolean;
  messages: string[];
  handleClose: (message: string) => void;
  type: 'success' | 'warning' | 'error';
};

const PrintSnackBar: React.FC<CustomSnackbarProps> = ({ open, messages, handleClose, type }) => {
  const classes = useStyles();
  const Icon = type === 'success' ? CheckCircleOutline : type === 'warning' ? WarningOutlined : ErrorOutline;

  return (
    <>
      {messages.map((message, i) => (
        <Snackbar
          key={`${message}-${i}`} // use both message and index for key
          open={open}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              handleClose(message);
            }
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ marginBottom: `${i * 70}px` }}
        >
          <SnackbarContent
            className={classes[type]}
            message={
              <div className={classes.message}>
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Icon className={classes.icon} />
                  {message}
                  <Close onClick={() => handleClose(message)} className={classes.icon} style={{ cursor: 'pointer' }} />
                </span>
              </div>
            }
          />
        </Snackbar>
      ))}
    </>
  );
};

export default React.memo(PrintSnackBar);
