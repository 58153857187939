import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User, UserReducer } from './userTypes';
import {
  appleLogin,
  createGuestUser,
  getUser,
  loginGoogle,
  loginUser,
  logoutUser,
  registerUser,
  updateUser,
} from './userServices';

const initialState: UserReducer = {
  loading: 'idle',
  internetState: null,
  isNewUser: false,
  error: '',
  currentUser: {
    access_token: '',
    user_id: null,
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    refresh_token: '',
    expires_in: 0,
    isAdmin: false,
    company_name: '',
    country: '',
  },
  userType: 'guest',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.currentUser.access_token = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    setUserInfo: (state, action) => {
      state.currentUser = action.payload;
    },
    clearUserSlice: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = 'succeeded';
        state.userType = 'normal';
        state.currentUser = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(loginGoogle.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.userType = 'social';
        state.loading = 'succeeded';
      })
      .addCase(loginGoogle.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(loginGoogle.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.userType = 'normal';
        state.loading = 'succeeded';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action?.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(createGuestUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(createGuestUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentUser = action.payload;
        }
        state.loading = 'succeeded';
      })
      .addCase(createGuestUser.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getUser.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.currentUser = {
          ...state.currentUser,
          ...action.payload,
        };
        state.loading = 'succeeded';
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
        const { access_token, refresh_token, ...restOfPayload } = action.payload;
        state.currentUser = {
          ...state.currentUser,
          ...restOfPayload,
          user_id: restOfPayload.id,
        };
        state.loading = 'succeeded';
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      })
      .addCase(appleLogin.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(appleLogin.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.userType = 'social';
        state.loading = 'succeeded';
      })
      .addCase(appleLogin.rejected, (state, action) => {
        state.error = action.payload as string | null;
        state.loading = 'failed';
      });
  },
});

export const { clearUserSlice, setToken, setUserType, setIsNewUser, setUserInfo } = userSlice.actions;

export default userSlice.reducer;
