import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Router from 'Router';
import Spinner from 'components/Spinner';
import { TypescriptManager } from './PrintNow/TypescriptManagerClass';
import PrintSupport from './components/PrintSupport/index';
import { printtTheme } from './styles/theme/layout';
import i18n from './i18nconfig';
import { persistor, store } from './store/configureStore';
import { sentry_dsn, settings } from './settings';

Sentry.init({
  dsn: sentry_dsn,
  environment: `${process.env.REACT_APP_NODE_ENV}`,
  release: 'Printt@' + process.env.REACT_APP_NODE_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});
declare global {
  interface Window {
    analytics: any;
    AppleID: any;
    typescriptManager: TypescriptManager;
  }
}

// Directly assign the new instance to window.typescriptManager
window.typescriptManager = new TypescriptManager();

const container = document.getElementById('root');
const root = createRoot(container!); // Create a root.

root.render(
  <ThemeProvider theme={printtTheme}>
    <CssBaseline />
    <GoogleOAuthProvider clientId={`${settings.google_client_id}`}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <PrintSupport>
              <Router />
            </PrintSupport>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </GoogleOAuthProvider>
  </ThemeProvider>,
);
