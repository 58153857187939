import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from 'store/api';
import { addressUrl, cc_ordersUrl, deliveryAndSelf_orderUrl, pricesUrl, servicesUrl } from 'settings';
import { CustomerDetailsFormTypes } from 'components/FormHoc/Forms/types';
import i18n from 'i18nconfig';
import { calculateTotalPages, calculateTotalpagesPerFile, getFileIds, getStateAndToken } from '../../../utils/helpers';
import { Order } from '../../../utils/types';
import { FileInfo } from '../file/types';
import { OrderDeliveryAddress } from './orderTypes';

export const getServices = createAsyncThunk('order/getServices', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { uploadedFiles } = state.file;
    const totalPages = calculateTotalPages(uploadedFiles);

    const { data } = await sendRequest('get', `${servicesUrl}/?pages=${totalPages}`, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getPrices = createAsyncThunk('order/postPrices', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { order, file, app, user } = state;

    const body = {
      is_color: order.options.is_color,
      is_duplex: order.options.is_duplex,
      copies: order.options.copies,
      document_ids: getFileIds(file.uploadedFiles),
      documents_pages: calculateTotalpagesPerFile(file.uploadedFiles),
      pages: calculateTotalPages(file.uploadedFiles),
      documents: file.uploadedFiles.length,
      delivery_type: order.delivery_type,
      service_name: order.service_type,
      lamination: order.options.lamination,
      binding: order.options.binding,
      ...(order.service_type === 'click_and_collect' && { location_id: app.selectedShop?.id }),
      ...(user.userType === 'guest' && order.guest_email && { is_guest: true, email: order.guest_email }),
    };
    const { data } = await sendRequest('post', pricesUrl, body, `Bearer ${access_token}`);

    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const createSelfOrDeliveryService = createAsyncThunk('order/createOrder', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const { userType } = state.user;
    const { order, file, app } = state;

    const documentsId = file.uploadedFiles.map((file: FileInfo) => Number(file.id));

    let printerId;
    if (order.service_type === 'self_service') {
      if (!app.selectedPrinter) {
        throw new Error('Selected printer is null or undefined');
      }
      printerId = app.selectedPrinter.id;
    } else if (order.service_type === 'click_and_collect') {
      if (!app.selectedShop) {
        throw new Error('Selected shop is null or undefined');
      }
      printerId = app.selectedShop.id;
    } else {
      // For delivery, there is no selected shop or printer
      printerId = null;
    }

    const body = {
      service_type: order.service_type,
      is_draft: false,
      printer: printerId,
      documents: documentsId,
      documents_pages: calculateTotalpagesPerFile(file.uploadedFiles),
      options: {
        is_color: order.options.is_color,
        is_duplex: order.options.is_duplex,
        copies: order.options.copies,
        delivery_type: order.delivery_type,
        lamination: order.options.lamination,
        binding: order.options.binding,
        paper_size: order.service_type === 'self_service' ? 'A4' : order.options.paper_size,
      },
      donation: order.pricing.donation ? order.pricing.donation : false,
      total_pages: calculateTotalPages(file.uploadedFiles),
      ...(userType === 'guest' && { guest_email: order.guest_email }),
    };

    const { data } = await sendRequest('post', deliveryAndSelf_orderUrl, body, `Bearer ${access_token}`);

    return data;
  } catch (error: any) {
    console.error('Error in createSelfOrDeliveryService:', error); // Debugging statement
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const createClickAndCollectOrder = createAsyncThunk('order/createCcOrder', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { userType } = state.user;
    const { order, file, app } = state;

    const shopId = app.selectedShop?.id;

    const body = {
      document_ids: getFileIds(file.uploadedFiles),
      copies: order.options.copies,
      is_color: order.options.is_color,
      is_duplex: order.options.is_duplex,
      binding: order.options.binding,
      lamination: order.options.lamination,
      service_name: order.service_type,
      location_id: shopId,
      paper_size: order.options.paper_size,
      donation: order.pricing.donation,
      ...(userType === 'guest' && {
        guest_email: order.guest_email,
        guest_first_name: order.orderDeliveryAddress?.recipient_first_name,
        guest_last_name: order.orderDeliveryAddress?.recipient_last_name,
      }),
    };

    const { data } = await sendRequest('post', cc_ordersUrl, body, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const ApierrorMessage = error.response ? error.response.data[0] : error.message;
    const errorMessage = ApierrorMessage ? ApierrorMessage : `${i18n.t('messages.errorTryAgain')}`;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getOrder = createAsyncThunk('order/getCurrentOrder', async ({ order }: { order: Order }, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const url =
      order.service_type === 'click_and_collect'
        ? `${cc_ordersUrl}${order.id}`
        : order.service_type === 'delivery'
          ? `${deliveryAndSelf_orderUrl}${order.options?.printed_document_id}`
          : `${deliveryAndSelf_orderUrl}${order.id}`;

    const { data } = await sendRequest('get', url, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getUserOrders = createAsyncThunk(
  'order/getUserOrders',
  async ({ orderQuery }: { orderQuery: any }, thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);
      const { data } = await sendRequest(
        'get',
        `${cc_ordersUrl}?limit=${51}&offset=0&query=${orderQuery}`,
        null,
        `Bearer ${access_token}`,
      );
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const createOrderAddress = createAsyncThunk(
  'order/createUserDeliveryAddress',
  async (formData: OrderDeliveryAddress | CustomerDetailsFormTypes, thunkApi) => {
    const [state, access_token] = getStateAndToken(thunkApi);
    try {
      const { data } = await sendRequest('post', addressUrl, formData, `Bearer ${access_token}`);
      thunkApi.dispatch(getOrderAddress());
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const updateOrderAddress = createAsyncThunk(
  'order/createUserDeliveryAddress',
  async (formData: OrderDeliveryAddress | CustomerDetailsFormTypes, thunkApi) => {
    const [state, access_token] = getStateAndToken(thunkApi);
    try {
      const { data } = await sendRequest('put', addressUrl, formData, `Bearer ${access_token}`);
      thunkApi.dispatch(getOrderAddress());
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const cancelOrder = createAsyncThunk('order/cancelOrder', async ({ id }: { id: string }, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest('patch', deliveryAndSelf_orderUrl, id, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getOrderAddress = createAsyncThunk('order/getOrderAddress', async (_, thunkApi) => {
  const [state, access_token] = getStateAndToken(thunkApi);
  try {
    const { data } = await sendRequest('get', addressUrl, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});
