import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import { setSnackbar } from '../../store/features/app/appSlice';
interface File {
  id: string;
  status: 'PROCESSING' | 'READY';
  // other properties...
}
const FileStatusListener = () => {
  const dispatch = useAppDispatch();
  const files = useAppSelector((state) => state.file.uploadedFiles);
  const { t } = useTranslation();
  const [prevStatuses, setPrevStatuses] = useState<Record<string, 'PROCESSING' | 'READY'>>({});

  useEffect(() => {
    const newPrevStatuses = { ...prevStatuses };
    files.forEach((file: any) => {
      if (file.status === 'READY' && prevStatuses[file.id] === 'PROCESSING') {
        dispatch(setSnackbar({ open: true, message: t('messages.fileUploadSuccess'), type: 'success' }));
      }
      newPrevStatuses[file.id] = file.status;
    });
    setPrevStatuses(newPrevStatuses);
  }, [dispatch, files]);

  return null;
};

export default FileStatusListener;
