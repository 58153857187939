import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderDeliveryAddress, OrderSlice } from './orderTypes';
import {
  createClickAndCollectOrder,
  createOrderAddress,
  createSelfOrDeliveryService,
  getOrder,
  getOrderAddress,
  getPrices,
  getServices,
  getUserOrders,
} from './orderServices';

const initialState: OrderSlice = {
  loading: 'idle',
  error: '',
  orderHistory: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  currentOrder: null,
  options: {
    paper_size: 'A4',
    is_color: false,
    copies: 1,
    is_duplex: false,
    binding: false,
    lamination: false,
    paperThickness: '90gsm',
    paperType: 'standard',
  },
  instantQuoteStep: 0,
  optionStep: 0,
  serviceStep: 0,
  service_type: 'click_and_collect',
  delivery_type: 'nextday',
  orderDeliveryAddress: {
    recipient_first_name: '',
    recipient_last_name: '',
    recipient_email: '',
    address_first_line: '',
    address_second_line: '',
    city: '',
    postcode: '',
    phone_number: '',
    country: '',
  },
  services: [],
  guest_email: '',
  pricing: {
    initial_fee: 0,
    minimum_price: 0,
    donation: false,
    subtotal: 0,
    total: 0,
    total_without_discount: 0,
    voucher: {
      usages: 0,
      created_at: '',
      updated_at: '',
      id: '',
      name: '',
      discount_type: '',
      discount_target: '',
      value: 0,
      constraints: [],
      min_order_price: 0,
    },
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPaperSize: (state, action) => {
      state.options.paper_size = action.payload;
    },
    setColorPrinting: (state, action) => {
      state.options.is_color = action.payload;
    },
    setCopies: (state, action) => {
      state.options.copies = action.payload;
    },
    setDoubleSided: (state, action) => {
      state.options.is_duplex = action.payload;
    },
    setBinding: (state, action) => {
      state.options.binding = action.payload;
    },
    setLamination: (state, action) => {
      state.options.lamination = action.payload;
    },
    setPaperThickness: (state, action) => {
      state.options.paperThickness = action.payload;
    },
    setInstantQouteStep: (state, action) => {
      state.instantQuoteStep = action.payload;
    },
    setOptionStep: (state, action) => {
      state.optionStep = action.payload;
    },
    setServiceStep: (state, action) => {
      state.serviceStep = action.payload;
    },
    setServiceType: (state, action) => {
      state.service_type = action.payload;
    },
    setDeliveryType: (state, action) => {
      state.delivery_type = action.payload;
    },
    setOrderDeliveryAddress: (state, action) => {
      state.orderDeliveryAddress = action.payload;
    },
    setGuestEmail: (state, action) => {
      state.guest_email = action.payload;
    },
    clearOrderSlice: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.services = action.payload;
    });
    builder.addCase(getPrices.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getPrices.fulfilled, (state, action) => {
      state.pricing = action.payload;
    });
    builder.addCase(getPrices.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(createSelfOrDeliveryService.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createSelfOrDeliveryService.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.currentOrder = action.payload;
    });
    builder.addCase(createClickAndCollectOrder.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createClickAndCollectOrder.fulfilled, (state, action) => {
      state.loading = 'succeeded';
    });
    builder.addCase(createClickAndCollectOrder.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(getOrderAddress.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getOrderAddress.fulfilled, (state, action: PayloadAction<OrderDeliveryAddress>) => {
      state.loading = 'succeeded';
      state.orderDeliveryAddress = { ...state.orderDeliveryAddress, ...action.payload };
    });
    builder.addCase(getOrderAddress.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(createOrderAddress.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createOrderAddress.fulfilled, (state, action: PayloadAction<OrderDeliveryAddress>) => {
      state.loading = 'succeeded';
      state.orderDeliveryAddress = action.payload;
    });
    builder.addCase(createOrderAddress.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
    builder.addCase(getUserOrders.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getUserOrders.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.orderHistory = action.payload;
    });
    builder.addCase(getUserOrders.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice.reducer;
